<template>
    <sf-section v-if="leadText" class="article">
        <div v-html="leadText" class="lead" />  <!-- eslint-disable-line -->
    </sf-section>
</template>

<script>
import { get } from 'lodash';
import contentBlockMixin from '~/mixins/content-block';
import SfSection from '~/patterns/organisms/section/section';

export default {
    components: {
        SfSection,
    },

    mixins: [
        contentBlockMixin
    ],

    computed: {
        leadText() {
            return get(this, 'block.wysiwyg', '');
        }
    }
};
</script>
