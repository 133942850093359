<template>
    <div class="sf-content-container">
        <slot />
    </div>
</template>

<script>
export default {

};
</script>

<style lang="less" src="./content-container.less" />
