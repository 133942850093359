export default {
    props: {
        block: {
            type: Object,
            required: true,
            default: () => { return {}; }
        },

        index: {
            type: Number,
            required: false,
            default: 0
        }
    }
};
