<template>
    <sf-section v-if="block && block.accordion && block.accordion.length">
        <template v-if="block.title" v-slot:title>
            {{ block.title }}
        </template>

        <template v-slot:default>
            <sf-content-container>
                <sf-accordion
                    :items="block.accordion"
                    :heading-tag="block.title ? 'h3' : 'h2'"
                >
                    <template v-slot:default="{ item }">
                        <div class="article" v-html="item.content" /> <!-- eslint-disable-line -->
                    </template>
                    <template v-slot:footer>
                        <nuxt-link
                            v-if="link && link.url"
                            :to="link.url"
                        >
                            {{ link.title }}
                        </nuxt-link>
                    </template>
                </sf-accordion>
            </sf-content-container>
        </template>
    </sf-section>
</template>

<script>
import contentBlockMixin from '~/mixins/content-block';
import SfSection from '~/patterns/organisms/section/section';
import SfContentContainer from '~/patterns/organisms/content-container/content-container';
import SfAccordion from '~/patterns/molecules/accordion/accordion.vue';

export default {
    components: {
        SfSection,
        SfContentContainer,
        SfAccordion
    },

    mixins: [
        contentBlockMixin
    ],

    props: {
        link: {
            type: Object,
            default: null
        }
    }
};
</script>
